import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../sidebar/Sidebar";
import { Table, Select, Button } from "antd";

const Brand = ({ filter, year }) => {
  const [tierPerformanceFilter, setTierPerformanceFilter] = useState("SCAN")
  const [yearTierPerformanceFilter, setYearTierPerformanceFilter] = useState(2023)
  const [totalData, setTotalData] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [averageData, setAverageData] = useState([]);
  const [error, setError] = useState("");

  const columns = [
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Terminology",
      dataIndex: "terminology",
      key: "terminology",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];

  const kpiColumns = [
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Terminology",
      dataIndex: "terminology",
      key: "terminology",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];
  const averageColumns = [
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Terminology",
      dataIndex: "terminology",
      key: "terminology",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];

  // /api/admin/brand/monthlyActiveScanner
  const fetchTierPerformanceData = () => {
    const requestData = {
      year: yearTierPerformanceFilter,
      filter: tierPerformanceFilter,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/tier/performance", requestData, { headers })
      .then((response) => {
        const { total, kpi, average } = response.data;
        setTotalData(total);
        setKpiData(kpi);
        setAverageData(average);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  const fetchData = () => {
    fetchTierPerformanceData();
  }

  useEffect(() => {
    fetchData(); // Fetch data initially when the component mounts
  }, [
    tierPerformanceFilter,
    yearTierPerformanceFilter
  ]);

  const tierButton = (value) => {
    setTierPerformanceFilter(value);
  };

  const generateCSV = (columns, data, filename) => {
    const header = columns.map(column => column.title).join(',');
  
    const csvData = [header].concat(
      data.map(item => {
        return columns.map(column => {
          // Convert the value to a string and enclose in double quotes if it contains commas
          const value = String(item[column.dataIndex]);
          if (value.includes(',')) {
            return `"${value}"`;
          } else {
            return value;
          }
        }).join(',');
      })
    ).join('\n');
  
    const blob = new Blob([csvData], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  };
  

  const handleDownload1 = () => {
    generateCSV(columns, totalData, 'totalData.csv');
  };
  
  const handleDownload2 = () => {
    generateCSV(kpiColumns, kpiData, 'kpiData.csv');
  };
  
  const handleDownload3 = () => {
    generateCSV(averageColumns, averageData, 'averageData.csv');
  };

  return (
    <div className="homepage">
      <Sidebar />
      <div className="content">
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{textAlign: "left", color: "blue"}}>Performance by Tiers</h2>
         <div style={{ display: "flex" }}>
            {/*  <Select
              value={tierPerformanceFilter}
              onChange={setTierPerformanceFilter}
            >
              <Select.Option value="SCAN">Scan</Select.Option>
              <Select.Option value="POINT">Point</Select.Option>
              <Select.Option value="SALES">Sales Value (USD)</Select.Option>
            </Select> */}
             <div style={{display: "flex"}}>
              <button
                onClick={() => tierButton("SCAN")}
                className={tierPerformanceFilter === "SCAN" ? "active" : ""}
              >
                Scan
              </button>
              <button
                onClick={() => tierButton("POINT")}
                className={tierPerformanceFilter === "POINT" ? "active" : ""}
              >
                Point
              </button>
              <button
                onClick={() => tierButton("SALES")}
                className={tierPerformanceFilter === "SALES" ? "active" : ""}
              >
                Sales Value (USD)
              </button>
            </div>
            <Select
              value={yearTierPerformanceFilter}
              onChange={setYearTierPerformanceFilter}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <h5>Total User</h5>
          <Table dataSource={totalData} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload1} style={{marginBottom: "20px"}}>Download Data</Button>

          {/* <h2>KPI Data</h2> */}
          <h5>Scans, Points, Sales Values (KPI)</h5>
          <Table dataSource={kpiData} columns={kpiColumns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload2} style={{marginBottom: "20px"}}>Download Data</Button>

          {/* <h2>Average Data</h2> */}
          <h5>Average Rate</h5>
          <Table dataSource={averageData} columns={averageColumns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload3} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
      </div>
    </div>
  );
};

export default Brand;
