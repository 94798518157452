import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dInsight from "../image/dInsightLogo.png";
import abbottLogo from "../image/abbottLogo.png";
import powered from "../image/poweredbyTDG.png"

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Ehhh");
      return;
    }

    axios
      .post("/api/auth/login", { username, password })
      .then((response) => {
        const { token } = response.data;
        localStorage.setItem("auth-token", token);
        navigate("/overview");
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  return (
    <div className="admin-portal">
      <div className="login-container">
        <div className="left-box">
          <img
            src={abbottLogo}
            alt="Abbott Logo"
            style={{ height: "auto", maxWidth: "75%" }}
          />
          <h3 className="mac-text">My Abbott Cares</h3>
        </div>
        <div className="whiteCover"></div>
        <div className="right-box">
          <div className="right-content">
            <form
              style={{ textAlign: "center", marginTop: "40%" }}
              onSubmit={handleSubmit}
            >
              <img src={dInsight} alt="Login Image" style={{ width: "35%" }} />
              <h2 style={{ color: "#169ad7" }}>Login</h2>
              {error && <p className="error">{error}</p>}
              <div className="form-group">
                <label
                  style={{ color: "#169ad7", textAlign: "left" }}
                  htmlFor="username"
                >
                  ID:
                </label>
                <br></br>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={handleInputChange}
                  placeholder="Enter your ID"
                />
              </div>
              <div className="form-group">
                <label
                  style={{ color: "#169ad7", textAlign: "left" }}
                  htmlFor="password"
                >
                  Password:
                </label>
                <br></br>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                />
              </div>
              <button style={{ borderRadius: "30px", width: "100%", marginTop: "20px" }} type="submit">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
      <img src={powered} className="powered-img" />
    </div>
  );
};

export default Login;
