import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../sidebar/Sidebar";
import { Table, Select, Button } from "antd";

const Brand = () => {
  const [monthlyPerformanceFilter, setMonthlyPerformanceFilter] =
    useState("SCAN"); // State for Monthly Performance filter
  const [userPerformanceFilter, setUserPerformanceFilter] = useState("ACTIVE"); // State for User Performance filter
  const [yearMonthlyPerformance, setYearMonthlyPerformance] = useState(2023); // State for year
  const [yearUserPerformance, setYearUserPerformance] = useState(2023); // State for year
  const [yearMonthlyActiveScanner, setYearMonthlyActiveScanner] =
    useState(2023); // State for year
  const [monthlyPerformanceData, setMonthlyPerformanceData] = useState([]);
  const [userPerformanceData, setUserPerformanceData] = useState([]);
  const [monthlyActiveScanner, setMonthlyActiveScannerData] = useState([]);
  const [error, setError] = useState("");

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];

  // Function to fetch Monthly Performance data
  const fetchMonthlyPerformanceData = () => {
    const requestData = {
      filter: monthlyPerformanceFilter,
      year: yearMonthlyPerformance,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/brand/monthlyPerformance", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setMonthlyPerformanceData(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // Function to fetch User Performance data
  const fetchUserPerformanceData = () => {
    const requestData = {
      filter: userPerformanceFilter,
      year: yearUserPerformance,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/brand/userPerformance", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setUserPerformanceData(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  const fetchMonthlyActiveScannerData = () => {
    const requestData = {
      year: yearMonthlyActiveScanner,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/brand/monthlyActiveScanner", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setMonthlyActiveScannerData(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // Function to fetch data for both Monthly and User Performance
  const fetchData = () => {
    fetchMonthlyPerformanceData();
    fetchUserPerformanceData();
    fetchMonthlyActiveScannerData();
  };

  useEffect(() => {
    fetchData(); // Fetch data initially when the component mounts
  }, [
    monthlyPerformanceFilter,
    userPerformanceFilter,
    yearMonthlyPerformance,
    yearUserPerformance,
    yearMonthlyActiveScanner,
  ]);

  const monthlyPerformanceButton = (value) => {
    setMonthlyPerformanceFilter(value);
  };

  const userPerformanceButton = (value) => {
    setUserPerformanceFilter(value);
  };

// Reusable function to generate CSV
const generateCSV = (columns, data, filename) => {
  const header = columns.map(column => column.title).join(',');

  const csvData = [header].concat(
    data.map(item => {
      return columns.map(column => {
        // Convert the value to a string and enclose in double quotes if it contains commas
        const value = String(item[column.dataIndex]);
        if (value.includes(',')) {
          return `"${value}"`;
        } else {
          return value;
        }
      }).join(',');
    })
  ).join('\n');

  const blob = new Blob([csvData], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  downloadLink.click();
};

const handleDownload3 = () => {
  generateCSV(columns, monthlyPerformanceData, 'monthlyPerformanceData.csv');
};

const handleDownload2 = () => {
  generateCSV(columns, userPerformanceData, 'userPerformanceData.csv');
};

const handleDownload1 = () => {
  generateCSV(columns, monthlyActiveScanner, 'monthlyActiveScanner.csv');
};

  

  return (
    <div className="homepage">
      <Sidebar />
      <div className="content">
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{textAlign: "left", color: "blue"}}>Monthly Performance by Brand</h2>
          <div style={{ display: "flex" }}>
            {/* <Select
              value={monthlyPerformanceFilter}
              onChange={setMonthlyPerformanceFilter}
            >
              <Select.Option value="SCAN">Scan</Select.Option>
              <Select.Option value="POINT">Point</Select.Option>
              <Select.Option value="SALES">Sales Value (USD)</Select.Option>
            </Select> */}
             <div style={{display: "flex"}}>
              <button
                onClick={() => monthlyPerformanceButton("SCAN")}
                className={monthlyPerformanceFilter === "SCAN" ? "active" : ""}
              >
                Scan
              </button>
              <button
                onClick={() => monthlyPerformanceButton("POINT")}
                className={monthlyPerformanceFilter === "POINT" ? "active" : ""}
              >
                Point
              </button>
              <button
                onClick={() => monthlyPerformanceButton("SALES")}
                className={monthlyPerformanceFilter === "SALES" ? "active" : ""}
              >
                Sales Value (USD)
              </button>
            </div>
            <Select
              value={yearMonthlyPerformance}
              onChange={setYearMonthlyPerformance}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div><br></br>
          <Table dataSource={monthlyPerformanceData} columns={columns} pagination={false}  /><br></br>
          <Button onClick={handleDownload3} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{textAlign: "left", color: "blue"}}>Users Performance by Brand</h2>
          <div style={{ display: "flex" }}>
            {/* <Select
              value={userPerformanceFilter}
              onChange={setUserPerformanceFilter}
            >
              <Select.Option value="ACTIVE">Active</Select.Option>
              <Select.Option value="RETAIN">Retained</Select.Option>
            </Select> */}
             <div style={{display: "flex"}}>
              <button
                onClick={() => userPerformanceButton("ACTIVE")}
                className={userPerformanceFilter === "ACTIVE" ? "active" : ""}
              >
                Active
              </button>
              <button
                onClick={() => userPerformanceButton("RETAIN")}
                className={userPerformanceFilter === "RETAIN" ? "active" : ""}
              >
                Retained
              </button>
            </div>
            <Select
              value={yearUserPerformance}
              onChange={setYearUserPerformance}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div><br></br>
          <Table dataSource={userPerformanceData} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload2} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{textAlign: "left", color: "blue"}}>Monthly Active Scanner by Brand</h2>
          <div style={{ display: "flex" }}>
            <Select
              value={yearMonthlyActiveScanner}
              onChange={setYearMonthlyActiveScanner}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div><br></br>
          <Table dataSource={monthlyActiveScanner} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload1} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
      </div>
    </div>
  );
};

export default Brand;
