import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';

const Homepage = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the token from localStorage
    const token = localStorage.getItem('auth-token');

    if (!token) {
      setAuthenticated(false);
      return;
    }

    // Send a request to checkToken API
    axios
      .post('/api/auth/checkToken', { token })
      .then((response) => {
        const { data } = response.data;
        setUserData(data);
        setAuthenticated(true);
      })
      .catch((error) => {
        if (error.response && error.response.data.error === 'Token Expired') {
          setAuthenticated(false);
        } else {
          console.error(error);
        }
      });
  }, []);

  return (
    <div className="homepage">
      <Sidebar />

      {/* Content Container */}
      <div className="content">
        {authenticated ? (
          <div className="main-content">
            <h2>Welcome, {userData.username}!</h2>
            <p>Your permissions:</p>
            <ul>
              <li>Overview: {userData.overview ? 'Allowed' : 'Not Allowed'}</li>
              <li>Brand: {userData.brand ? 'Allowed' : 'Not Allowed'}</li>
              <li>Tier: {userData.tier ? 'Allowed' : 'Not Allowed'}</li>
            </ul>
          </div>
        ) : (
          <p>Please log in to access this page.</p>
        )}
      </div>
    </div>
  );
};

export default Homepage;
