import React from "react";
import { useNavigate } from "react-router-dom";
import abbottLogo from "../image/abbottLogo.svg"
import dInsight from "../image/dInsightLogo.svg"
import icon1 from "../image/icon1.svg";
import icon2 from "../image/icon2.svg";
import icon3 from "../image/icon3.svg"

const Sidebar = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };


  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img style={{width: "65%"}} src={abbottLogo} />
      </div>
      <ul className="sidebar-menu">
        <li>
          <a onClick={() => handleNavigation("/overview")}>
            <img style={{width: "10%", marginRight: "10px"}} src={icon1} /><span>Overview Data</span>
          </a>
        </li>
        <li>
          <a onClick={() => handleNavigation("/brand")}>
          <img style={{width: "10%", marginRight: "10px"}} src={icon2} /><span>Brand Data</span>
          </a>
        </li>
        <li>
          <a onClick={() => handleNavigation("/tier")}>
          <img style={{width: "10%", marginRight: "10px"}} src={icon3} /><span>Tiering Data</span>
          </a>
        </li>
        <li>
          <a onClick={() => {handleNavigation("/login");localStorage.clear();}}>
          <img style={{width: "10%", marginRight: "10px"}} /><span>Logout</span>
          </a>
        </li>
        <div className="sidebar-footer" style={{position: "fixed"}}>
          <img src={dInsight} alt="Your Logo" />
      </div>
      </ul>
    </div>
  );
};

export default Sidebar;
