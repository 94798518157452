import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../sidebar/Sidebar";
import { Table, Select, Button } from "antd";

const Overview = ({ filter, year }) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [data8, setData8] = useState([]);
  const [data9, setData9] = useState([]);
  const [error, setError] = useState("");

  const [overviewFilter, setOverviewFilter] = useState("SCAN");
  const [userPerformanceActiveFilter, setUserPerformanceActiveFilter] =
    useState("BRAND");
  // const [userPerformanceRetainFilter, setUserPerformanceRetainFilter] =
  //   useState("MOBILE");

  const [yearOverviewFilter, setYearOverviewFilter] = useState(2023);
  // const [yearUserPerformanceActiveFilter, setYearUserPerformanceActiveFilter] =
  //   useState(2023);
  // const [yearUserPerformanceRetainFilter, setYearUserPerformanceRetainFilter] =
  //   useState(2023);
  const [yearUserPerformanceTotal, setYearUserPerformanceTotal] =
    useState(2023);
  const [yearScanningRecordActive, setYearScanningRecordActive] =
    useState(2023);
  // const [yearScanningRecordRetain, setYearScanningRecordRetain] =
  //   useState(2023);
  const [yearRedemptionReport, setYearRedemptionReport] = useState(2023);
  const [yearElapsedMemberReport, setYearElapsedMemberReport] = useState(2023);
  const [yearMonthyActiveScanner, setYearMonthyActiveScanner] = useState(2023);

  const overviewColumns = [
    {
      title: "Metrics",
      dataIndex: "metrics",
      key: "metrics",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
    },
  ];

  // filter: SCAN, POINT, SALES
  // /api/admin/overview/overview
  const fetchOverview = () => {
    const requestData = {
      filter: overviewFilter,
      year: yearOverviewFilter,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/overview", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setData(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/userPerformance/total
  const fetchUserPerformanceTotal = () => {
    const requestData = {
      year: yearUserPerformanceTotal,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/userPerformance/total", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData2(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // filter: MOBILE, BRAND
  // /api/admin/overview/userPerformance/active
  const fetchUserPerformanceActive = () => {
    const requestData = {
      filter: userPerformanceActiveFilter,
      year: yearUserPerformanceTotal,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/userPerformance/active", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData3(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // filter: MOBILE, BRAND
  // /api/admin/overview/userPerformance/retain
  const fetchUserPerformanceRetain = () => {
    const requestData = {
      filter: userPerformanceActiveFilter,
      year: yearUserPerformanceTotal,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/userPerformance/retain", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData4(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/scanningRecords/active
  const fetchScanningRecordActive = () => {
    const requestData = {
      year: yearScanningRecordActive,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/scanningRecords/active", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData5(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/scanningRecords/retain
  const fetchScanningRecordRetain = () => {
    const requestData = {
      year: yearScanningRecordActive,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/scanningRecords/retain", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData6(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/redemptionReport
  const fetchRedemptionRecord = () => {
    const requestData = {
      year: yearRedemptionReport,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/redemptionReport", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setData7(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/elapsedMemberReport
  const fetchElapsedMemberReport = () => {
    const requestData = {
      year: yearElapsedMemberReport,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/elapsedMemberReport", requestData, { headers })
      .then((response) => {
        const { data } = response.data;
        setData8(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  // /api/admin/overview/monthlyActiveScanners
  const fetchMonthlyActiveScanner = () => {
    const requestData = {
      year: yearMonthyActiveScanner,
    };
    const token = localStorage.getItem("auth-token");

    if (!token) {
      setError("Unauthorized Request");
      return;
    }

    // Set the headers for the request
    const headers = {
      "auth-token": token,
    };

    axios
      .post("/api/admin/overview/monthlyActiveScanners", requestData, {
        headers,
      })
      .then((response) => {
        const { data } = response.data;
        setData9(data);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Internal Error");
        }
      });
  };

  const fetchData = () => {
    fetchOverview();
    fetchUserPerformanceTotal();
    fetchUserPerformanceActive();
    fetchUserPerformanceRetain();
    fetchScanningRecordActive();
    fetchScanningRecordRetain();
    fetchRedemptionRecord();
    fetchElapsedMemberReport();
    fetchMonthlyActiveScanner();
  };

  useEffect(() => {
    fetchData(); // Fetch data initially when the component mounts
  }, [
    overviewFilter,
    userPerformanceActiveFilter,
    // userPerformanceRetainFilter,
    yearOverviewFilter,
    // yearUserPerformanceActiveFilter,
    // yearUserPerformanceRetainFilter,
    yearUserPerformanceTotal,
    yearScanningRecordActive,
    // yearScanningRecordRetain,
    yearRedemptionReport,
    yearElapsedMemberReport,
    yearMonthyActiveScanner,
  ]);

   const overviewButton = (value) => {
    setOverviewFilter(value);
  };

   const totalActiveButton = (value) => {
    setUserPerformanceActiveFilter(value);
  };

// Reusable function to generate CSV
const generateCSV = (columns, data, filename) => {
  const header = columns.map(column => column.title).join(',');
  
  const csvData = [header].concat(
    data.map(item => {
      return columns.map(column => {
        // Convert the value to a string and enclose in double quotes if it contains commas
        const value = String(item[column.dataIndex]);
        if (value.includes(',')) {
          return `"${value}"`;
        } else {
          return value;
        }
      }).join(',');
    })
  ).join('\n');

  const blob = new Blob([csvData], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  downloadLink.click();
};

// Example usage for multiple downloads
const handleDownload1 = () => {
  generateCSV(overviewColumns, data, 'data.csv');
};

const handleDownload2 = () => {
  generateCSV(columns, data2, 'data2.csv');
};

const handleDownload3 = () => {
  generateCSV(columns, data3, 'data3.csv');
};

const handleDownload4 = () => {
  generateCSV(columns, data4, 'data4.csv');
};

const handleDownload5 = () => {
  generateCSV(columns, data5, 'data5.csv');
};

const handleDownload6 = () => {
  generateCSV(columns, data6, 'data6.csv');
};

const handleDownload7 = () => {
  generateCSV(columns, data7, 'data7.csv');
};

const handleDownload8 = () => {
  generateCSV(columns, data8, 'data8.csv');
};

const handleDownload9 = () => {
  generateCSV(columns, data9, 'data9.csv');
};



  return (
    <div className="homepage">
      <Sidebar />
      <div className="content">
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>Overview</h2>
          <div style={{ display: "flex" }}>
            {/* <Select value={overviewFilter} onChange={setOverviewFilter}>
              <Select.Option value="SCAN">SCAN</Select.Option>
              <Select.Option value="POINT">POINT</Select.Option>
              <Select.Option value="SALES">SALES</Select.Option>
            </Select> */}
              <div style={{display: "flex"}}>
              <button
                onClick={() => overviewButton("SCAN")}
                className={overviewFilter === "SCAN" ? "active" : ""}
              >
                Scan
              </button>
              <button
                onClick={() => overviewButton("POINT")}
                className={overviewFilter === "POINT" ? "active" : ""}
              >
                Point
              </button>
              <button
                onClick={() => overviewButton("SALES")}
                className={overviewFilter === "SALES" ? "active" : ""}
              >
                Sales Value (USD)
              </button>
            </div>
            <Select value={yearOverviewFilter} onChange={setYearOverviewFilter}>
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <br></br>
          <Table dataSource={data} columns={overviewColumns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload1} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>

        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>
            User Performance
          </h2>
          <div style={{ display: "flex" }}>
          <div style={{display: "flex"}}>
              <button
                onClick={() => totalActiveButton("MOBILE")}
                className={userPerformanceActiveFilter === "MOBILE" ? "active" : ""}
              >
                Mobile
              </button>
              <button
                onClick={() => totalActiveButton("BRAND")}
                className={userPerformanceActiveFilter === "BRAND" ? "active" : ""}
              >
                Brand
              </button>
            </div>
            <Select
              value={yearUserPerformanceTotal}
              onChange={setYearUserPerformanceTotal}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          { userPerformanceActiveFilter === "MOBILE" ?
          (
          <div><h5>Total Registered Users (Appear in Mobile Only)</h5>
          <Table dataSource={data2} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload2} style={{marginBottom: "20px"}}>Download Data</Button></div>) :
          null
          }
        </div>
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          {/* <h2>Active User Performance</h2> */}
          <div style={{ display: "flex" }}>
            {/* <Select
              value={userPerformanceActiveFilter}
              onChange={setUserPerformanceActiveFilter}
            >
              <Select.Option value="MOBILE">MOBILE</Select.Option>
              <Select.Option value="BRAND">BRAND</Select.Option>
            </Select> */}
            {/* <Select
              value={yearUserPerformanceActiveFilter}
              onChange={setYearUserPerformanceActiveFilter}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
               Add other year options 
            </Select> */}
          </div>
          <h5>Total Active Users</h5>
          <Table dataSource={data3} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload3} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          {/* <h2>Retain User Performance</h2> */}
          <div style={{ display: "flex" }}>
            {/* <Select
              value={userPerformanceRetainFilter}
              onChange={setUserPerformanceRetainFilter}
            >
              <Select.Option value="MOBILE">MOBILE</Select.Option>
              <Select.Option value="BRAND">BRAND</Select.Option>
            </Select> */}
            {/* <div style={{display: "flex"}}>
              <button
                onClick={() => totalRetainButton("ACTIVE")}
                className={userPerformanceRetainFilter === "ACTIVE" ? "active" : ""}
              >
                Active
              </button>
              <button
                onClick={() => totalRetainButton("RETAIN")}
                className={userPerformanceRetainFilter === "RETAIN" ? "active" : ""}
              >
                Retained
              </button>
            </div> */}
            {/* <Select
              value={yearUserPerformanceRetainFilter}
              onChange={setYearUserPerformanceRetainFilter}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
               Add other year options 
            </Select> */}
          </div>
          <h5>Total Retained Users</h5>
          <Table dataSource={data4} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload4} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>

        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>Scanning Records</h2>
          <div style={{ display: "flex" }}>
            <Select
              value={yearScanningRecordActive}
              onChange={setYearScanningRecordActive}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <h5>1st Scan Records</h5>
          <Table dataSource={data5} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload5} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          {/* <h2>Retains Scanning Records</h2> */}
          <div style={{ display: "flex" }}>
            {/* <Select
              value={yearScanningRecordRetain}
              onChange={setYearScanningRecordRetain}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
               Add other year options 
            </Select> */}
          </div>
          <h5>2nd Scan+ Records</h5>
          <Table dataSource={data6} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload6} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>

        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>
            Redemption Report
          </h2>
          <div style={{ display: "flex" }}>
            <Select
              value={yearRedemptionReport}
              onChange={setYearRedemptionReport}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <h5>Loyalty Redemption</h5>
          <Table dataSource={data7} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload7} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>

        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>
            Elapsed Member Report
          </h2>
          <div style={{ display: "flex" }}>
            <Select
              value={yearElapsedMemberReport}
              onChange={setYearElapsedMemberReport}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <h5>Elapsed Member</h5>
          <Table dataSource={data8} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload8} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>

        <div className="monthly-performance-table">
          {error && <p className="error">{error}</p>}
          <h2 style={{ textAlign: "left", color: "blue" }}>
            Monthly Active Scanner
          </h2>
          <div style={{ display: "flex" }}>
            <Select
              value={yearMonthyActiveScanner}
              onChange={setYearMonthyActiveScanner}
            >
              <Select.Option value={2023}>2023</Select.Option>
              <Select.Option value={2022}>2022</Select.Option>
              <Select.Option value={2021}>2021</Select.Option>
              <Select.Option value={2020}>2020</Select.Option>
              <Select.Option value={2019}>2019</Select.Option>
              <Select.Option value={2018}>2018</Select.Option>
              {/* Add other year options */}
            </Select>
          </div>
          <h5>Monthly Active Scanners (%)</h5>
          <Table dataSource={data9} columns={columns} pagination={false}  /> <br></br>
          <Button onClick={handleDownload9} style={{marginBottom: "20px"}}>Download Data</Button>
        </div>
      </div>
    </div>
  );
};

export default Overview;
