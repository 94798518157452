import './App.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Brand from './components/page/Brand';
import Homepage from '../src/components/page/Homepage'
import Login from '../src/components/page/Login'
import Overview from './components/page/Overview';
import Tier from  './components/page/Tier';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route index path="/homepage" element={<Homepage />} />
          <Route index path="/brand" element={<Brand />} />
          <Route index path="/overview" element={<Overview />} />
          <Route index path="/tier" element={<Tier />} />
          <Route path="*" element={<Navigate to="/login" />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
